import * as React from "react"
import Layout from "../../components/layout"
import AniLink from "gatsby-plugin-transition-link/AniLink";
import Helmet from 'react-helmet'
const bills = () => {
  return (
  <>
<Helmet
    bodyAttributes={{
        class: 'single-page whitemain'
    }}
/>
  <Layout pageTitle="bills">
    <section id="main">
      <div id="worktitle">
        <span>
          <h1>Bills Forecast</h1>
          <span className="highlight"></span>
        </span>
        <span>Minus4º</span>
      </div>
    </section>

    <section id="work">
      <div className="work">
          <div className="picture workpicture">
		  	<img src={'/img/works/bill-forecast-feature.jpg'} alt="Bills Forecast" />

          </div>

        <div className="seeWork">
          <div className="seeWork__content">
            <div className="icons">
              <img className="picture" src={'/img/single/ios.svg'} width="45" height="52" alt="iOS"/>

            </div>
            <a className="button visitwebsite" href="https://apps.apple.com/app/bills-forecast/id767762923" target="_blank" rel="noreferrer"><span className="seemore"><i>&#xe801;</i></span>Go to App Store</a>
          </div>
        </div>

      </div>
    </section>

    <section id="single-content">
      <div className="single-content">
        <p>Bills Forecast makes budgeting easier. Providing 3 different levels with push notification and the ability to add to your calendar at the same time. Beautifully designed for iOS and iCloud sync for both iPhone and iPad.</p>
        <p>Minus4º deliver brand, strategise, design and develop powerful mobile applications on the major mobile and tablet operating systems. Tobstar’s founder is also co-founder of this application company in which he is involved with co-founder in concepts, design, strategise, localisation, marketing, apps search optimisation and programming for software applications.</p>
        <div className="video-container">
        <iframe src="https://www.youtube.com/embed/_-RcORkgnA0?autoplay=0&amp;showinfo=0&amp;rel=0&amp;hd=1" width="1140" height="auto" frameborder="0" allowfullscreen="allowfullscreen; accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" title="YouTube video player"></iframe>
         </div>

      </div>

      <div className="project-image">
        <div className="left-col">
          <img className="picture" src={'/img/single/ipad_E_Simplist_SS.jpg'} alt="Desktop View" />
        </div>
        <div className="right-col">
          <img className="picture" src={'/img/single/iPhone_Levels_SS.jpg'} alt="Mobile View" />
      </div>
      </div>
    </section>


    <section id="post-pagination">
      <div className="post-pagination">
        <div className="previous">
          <AniLink cover to="/work/early-learning-association-australia" bg="#00ccd4" direction="right" duration={0.76} className="button project prev">Prev Work</AniLink>
          <p className="project-name">ELAA</p>
        </div>
        <div className="next">
          <AniLink cover to="/work/history-month" bg="#00ccd4" direction="right" duration={0.76} className="button project next">Next Work</AniLink>
          <p className="project-name">History Month</p>
        </div>
      </div>
    </section>
</Layout>

  </>
  )
}

export default bills


export const Head = () => (
  <>
    <title>Bills Forecast - Tobstar</title>
<meta name="description" content="Bills Forecast makes budgeting easier. Providing 3 different levels with push notification and the ability to add to your calendar at the same time. Beautifully designed for iOS and iCloud sync for both iPhone and iPad." />
  </>
)